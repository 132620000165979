/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import FullEditor from '../../src/components/standalone/FullEditor';
function _createMdxContent(props) {
  return React.createElement("div", {
    style: {
      margin: '50px auto',
      maxWidth: 720
    }
  }, React.createElement(FullEditor));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
